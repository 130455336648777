<template>
    <div class="CooperativeItem">
        <div class="itemBox">
            <div class="breadcrumb">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: 'firmInfo' }">合作企业</el-breadcrumb-item>
                    <el-breadcrumb-item>投入资源</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="Detail">
                <CardHeader :title="'投入资源'" :pt="0" :pb="24" />
                <div class="itemLists">
                    <ItemListCard v-if="InvestmentPageList.length>0" :Path="'itemListInfo'" :apiPath="'enterpriseInvestment'" :data="InvestmentPageList" type="calendar"></ItemListCard>
                    <div v-else class="noData">~暂无数据~</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ItemListCard from "@/components/Common/ItemListCard.vue";
    import CardHeader from "@/components/Common/CardHeader.vue";
    import { dateTimeFilter } from "@/filters";
    import { getPageList } from "@/api/enterprise/resourceInvestment";
    export default {
        name: "ResourceInvestment",
        props: {},
        components: {
            CardHeader,
            ItemListCard
        },
        data() {
            return {
                loading: false,
                //
                InvestmentPageList: null,
                InvestmentServeData: {
                    EnterpriseId: this.$route.query.id,
                    PageSize: 10,
                    PageIndex: 1
                },
                Resume: {}
            };
        },
        computed: {
            formartDate() {
                return (data, formate) => dateTimeFilter(data, formate);
            }
        },
        watch: {},
        methods: {
            // 资源
            async getInvestmentList() {
                this.loading = true;
                try {
                    const { Result } = await getPageList(this.InvestmentServeData);
                    // let res = { ...Result, Type: "Investment" };
                    this.InvestmentPageList = Result.Data;
                    // console.log("资源007", this.InvestmentServeData);
                    this.loading = false;
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                }
            }
        },
        created() {
            this.getInvestmentList();
        },
        mounted() {}
    };
</script>

<style scoped lang="scss">
    .CooperativeItem {
        width: 100%;
        min-height: calc(100vh - 202px);
        background: #f5f5f5;
    }

    .itemBox {
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding-bottom: 40px;

        /deep/ .el-breadcrumb__item:nth-child(2) {
            color: #111;
        }

        /deep/ .el-breadcrumb__inner {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 20px;
        }

        .breadcrumb {
            box-sizing: border-box;
            width: 100%;
            height: 46px;
            padding: 16px 0 8px;
        }

        .Detail {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 32px;
            min-height: 66vh;
            background: #ffffff;
            border-radius: 8px;
            overflow: hidden;

            .itemLists {
                min-height: 70px;
            }
        }

        .noData {
            width: 100%;
            height: 200px;
            line-height: 200px;
            text-align: center;
            color: #557df7;
        }
    }
</style>